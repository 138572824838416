@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "roc-grotesk-condensed", sans-serif;
    box-sizing: border-box;
    background-color: #121212;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    margin: 0;
  }

  body {
    position: relative;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(187, 189, 192, 0.5);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(187, 189, 192, 0.6);
  }

  ::-ms-reveal {
    display: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:checked + div {
    @apply border-[#ED1941];
  }

  input:checked + div svg {
    @apply block;
  }

  td:last-child {
    border-right: none;
  }
  tr:last-child td {
    border-bottom: none;
  }
}

@layer components {
  .mates-border {
    @apply border-MatesBorder border-solid border-[1px];
  }
  .base-button {
    @apply rounded-[3px] flex items-center justify-center 
        text-xl font-medium outline-none drop-shadow-sm 
        transition-all duration-150 ease-in-out w-fit;
  }
  .small-base-button {
    @apply rounded-[3px] flex items-center justify-center 
        text-lg font-medium outline-none drop-shadow-sm 
        transition-all duration-150 ease-in-out w-full;
  }
  .flexi-base-button {
    @apply rounded-[5px] flex items-center justify-center 
      py-2 uppercase text-2xl leading-none font-bold outline-none drop-shadow-sm 
        transition-all duration-150 ease-in-out w-fit gap-2 font-compressed;
  }
  .red-button {
    @apply bg-MatesRed text-MatesWhite px-6 py-2;
  }
  .active-button {
    @apply bg-MatesRed text-MatesWhite px-5 py-3;
  }
  .small-active-button {
    @apply bg-MatesRed hover:bg-MatesRed text-MatesWhite px-5 py-1;
  }
  .disabled-light-button {
    @apply bg-MatesLightGrey text-MatesWhite disabled:cursor-not-allowed px-5 py-3;
  }
  .disabled-dark-button {
    @apply bg-MatesDisabled text-MatesWhite disabled:cursor-not-allowed px-5 py-3;
  }
  .disabled-tiktok-button {
    @apply bg-[#FFFFFF1A] text-MatesWhite/60 w-full disabled:cursor-not-allowed px-5 py-3;
  }
  .disabled-login-button {
    @apply bg-MatesLightBlack text-MatesBlack disabled:cursor-not-allowed px-5 py-3;
  }
  .black-button {
    @apply bg-MatesLightBlack text-MatesWhite px-5 py-3;
  }
  .small-black-button {
    @apply bg-MatesLightBlack hover:bg-MatesBorder text-MatesWhite px-5 py-1;
  }
  .upload-light-button {
    @apply bg-MatesWhite/10 text-MatesWhite px-7 py-1;
  }
  .upload-dark-button {
    @apply bg-MatesWhite text-MatesBlack px-7 py-1;
  }
  .upload-disabled-button {
    @apply bg-MatesBorder text-MatesBlack px-7 py-1;
  }
  .title-button {
    @apply font-medium px-4 pt-[6px] pb-1 rounded-[3px] h-fit
        flex items-center justify-center cursor-pointer 
        transition-all duration-300 tracking-wider
        fill-MatesWhite shadow-container;
  }
  .complete-button {
    @apply font-medium px-7 pt-[6px] pb-1 rounded-[3px] h-fit 
        flex items-center justify-center cursor-pointer transition-all duration-300 tracking-wider;
  }
  .empty-list {
    @apply text-xl text-MatesWhite w-full flex items-center
        justify-center mt-5 bg-MatesDisabled py-4 rounded-[3px];
  }
  .card-edit-icon {
    @apply h-[40px] w-[40px] flex items-center rounded-[3px] 
        justify-center bg-MatesLightBlack cursor-pointer 
        hover:bg-MatesBorder transition-all duration-300;
  }
  .close-icon {
    @apply h-[40px] w-[40px] flex items-center rounded-[3px] 
        justify-center bg-MatesWhite cursor-pointer 
        hover:bg-MatesWhite/80 transition-all duration-300;
  }
  .modal-close-button {
    @apply bg-MatesRed text-MatesWhite flex items-center justify-center px-4 py-2 rounded-[3px]
        text-xl drop-shadow-sm transition-all duration-200 ease-in-out hover:bg-MatesRed outline-none;
  }
  .main-detail-button {
    @apply bg-MatesRed/90 text-MatesWhite rounded-[3px]
        flex items-center justify-center px-2 py-1 drop-shadow-sm
        transition-all duration-200 ease-in-out 
        hover:bg-MatesRed outline-none;
  }
  .disable-detail-button {
    @apply bg-MatesGrey/90 text-MatesWhite rounded-[3px]
        flex items-center justify-center px-2 py-1 drop-shadow-sm
        transition-all duration-200 ease-in-out cursor-not-allowed
        hover:bg-MatesGrey outline-none;
  }
  .delete-goback-button {
    @apply text-MatesGreen flex items-center justify-center pl-2 pr-1 py-1
        transition-all duration-200 ease-in-out mx-2 outline-none;
  }
  .table-body {
    @apply odd:bg-MatesOffGrey odd:text-MatesWhite even:bg-MatesLightBlack even:text-MatesWhite p-4 text-xl font-condensed w-full;
  }
  .dashboard-layout {
    @apply w-screen h-screen overflow-hidden bg-MatesBlack font-compressed;
  }
  .dashboard-layout-div {
    @apply w-screen h-full overflow-hidden mt-5;
  }
  .title-card {
    @apply flex flex-col p-3 text-MatesWhite rounded-[3px] my-2 text-lg w-full cursor-pointer transition-all duration-300 shadow-sm;
  }
  .title-page {
    @apply flex items-center mx-auto text-5xl text-MatesWhite font-semibold tracking-wide;
  }
  .search-bar {
    @apply w-full border-MatesRed border-[1px] border-solid rounded-[3px] py-2 pl-[3rem] pr-[12rem] text-MatesWhite cursor-pointer bg-MatesBlack place-items-start font-condensed text-[14px] active:border-MatesRed;
  }
  .td-last-child td:last-child {
    border-right: none;
  }
  .tr-last-child tr:last-child td {
    border-bottom: none;
  }
  .challenge-title {
    @apply text-MatesWhite font-condensed text-lg;
  }
  .input-text-lg {
    @apply pt-2 pb-1 font-compressed font-bold text-5xl text-MatesWhite;
  }
  .input-text-sm {
    @apply font-condensed text-lg text-MatesWhite;
  }
  .input-text-red {
    @apply font-compressed font-bold text-xl text-MatesRed tracking-wider uppercase;
  }
  .base-tiktok-container {
    @apply border-solid border-MatesWhite border-[1px] rounded-[15px] 
        flex items-center justify-center text-MatesWhite/60 font-compressed;
  }
}

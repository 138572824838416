.snackbar_container {
    visibility: hidden;
    position: fixed;
    display: flex;
    width:fit-content;
    min-width: 480px;
    height: auto;
    border-radius: 10px;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    padding-left: 1em;
    padding-right: 1em;
    align-items: center;
    box-shadow: 
        0 3px 6px rgba(0, 0, 0, 0.16), 
        0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 100;
}

.snackbar_show {
    visibility: visible;
}

.position {
    top: 1rem;
    left: 1.5rem;
    -webkit-animation: fadein 1s, fadeout 0.5s;
    animation: fadein 1s, fadeout 0.5s;
    animation-duration: 0.8s;
}

.error {
    background-color: #D32F2F;
    color: #FFFFFF;
}
.warning {
    background-color: #F57C00;
    color: #FFFFFF;
}
.info {
    background-color: #0288D1;
    color: #FFFFFF;
}
.success {
    background-color: #388E3C;
    color: #FFFFFF;
}

@-webkit-keyframes fadein {
    from {
        top: 0; 
        opacity: 0;
    } to {
        top: 1rem; 
        opacity: 1;
    }
}
  
@keyframes fadein {
    from {
        top: 0; 
        opacity: 0;
    } to {
        top: 1rem; 
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        top: 1rem; 
        opacity: 1;
    } to {
        top: 0; 
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        top: 1rem; 
        opacity: 1;
    } to {
        top: 0; 
        opacity: 0;
    }
}

@media screen and (max-width: 450px) {
    .snackbar_container {
        width: 90%
    }
}